import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import DrawHelper from "../../../helpers/DrawHelper";
import useDimensions from "react-use-dimensions";
import {
  AXES_COLOR,
  AXES_STROKE_WIDTH,
  GRID_STROKE_COLOR,
  xaxisTimeFormatter,
  LINE_COLORS,
  GRID_DASH_ARRAY,
  GRID_THICKNESS,
} from "../../ui/templates";
import { CartesianGrid, ComposedChart, XAxis, YAxis, Tooltip, Line } from "recharts";
import SimpleNoDataMessageTool from "../../sleep/tools/SimpleNoDataMessageTool";
import BaselineHelper from "../../../helpers/BaselineHelper";

// const SPOTS_PER_DAY = 12 * 24;
// const MA_SIZE = 3;
const LINES = [
  { value: "raw", label: "Raw", color: LINE_COLORS.raw, width: 1, show: true },
  {
    value: "weighted",
    label: "Baseline",
    color: LINE_COLORS.baseline,
    width: 2,
    show: true,
  },
];

const PatientDayBRPMBaselineTool = (props) => {
  let { uuid, date, hasNoData, theme, baselineWindow = 15, tz=0, points = [], loading = false } = props;
  const [ref, { width, height }] = useDimensions();
  //   const [loading, setLoading] = useState(false);
  const [RrRawData, setRrRawData] = useState([]);
  const [RrProcessedData, setRrProcessedData] = useState([]);

  const [delta, setDelta] = useState(0.03);
  const [kValue, setKValue] = useState(0.2);
  const [ptime, setPtime] = useState(0);
  const [showLine, setShowLine] = useState({
    raw: true,
    ma: true,
    adpt: true,
    gap: true,
    weighted: true,
  });
  const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    // const _points = points.map((s) => {
    //   return {
    //     ...s,
    //     t: moment(s.timestamp).valueOf(),
    //     slot: moment(s.timestamp).valueOf(),
    //     date: moment(s.timestamp).format("HH:mm:ss"),
    //     respiration_rate: s.resp_q > 0 ? s.respiration_rate : null,
    //   };
    // });
    if (!points || points.length === 0) {
      return;
    }
    const _points = points.filter((x) => x.resp_q);
    setRrRawData(_points);
    setRrProcessedData(_points);
    setDataLength(_points.filter((x) => x.respiration_rate).length);
    // console.log(_points);
  }, [date, uuid, points]);

  let extraLines = DrawHelper.getExtraDottedChartsData(RrProcessedData, "respiration_rate");
  let RrWithGaps = RrProcessedData;

  for (let sp of RrWithGaps) {
    for (let el in extraLines) {
      const slot1 = extraLines[el]["points"][0].slot;
      const slot2 = extraLines[el]["points"][1].slot;
      if (slot1 === sp.slot) {
        sp[`respiration_rate_gap_${el}`] = extraLines[el]["points"][0][`respiration_rate_gap_${el}`];
      }
      if (slot2 === sp.slot) {
        sp[`respiration_rate_gap_${el}`] = extraLines[el]["points"][1][`respiration_rate_gap_${el}`];
      }
    }
  }

  let xticks = BaselineHelper.getXaxisTicksEachHour(moment(date).startOf("day"));
  let yticks = [];
  let RrList = RrProcessedData.map((x) => x.Rr).filter((x) => x !== null);

  let maxRr = Math.max(...RrList);
  //   let ymax = Math.floor(maxRr / 10) * 10;
  //   if (ymax >= 30) {
  //     yticks = [0, 8, 16, 24, 30, 30];
  //   } else if (ymax < 90 && ymax >= 80) {
  //     yticks = [80, 85, 90, 95, 100];
  //   } else if (ymax < 80 && ymax >= 70) {
  //     yticks = [70, 80, 90, 100];
  //   } else {
  //     const gap = Math.round((100 - ymax) / 5);
  //     for (let i = ymax; i <= 100; i += gap) {
  //       yticks.push(i);
  //     }
  //     yticks.push(100);
  //   }

  let startOfDay = moment(date).startOf("day").valueOf();
  let startOfNextDay = moment(date).startOf("day").add(1, "day").valueOf();
  const handleLineCheckbox = (evt) => {
    const name = evt.target.name;
    let oldValue = showLine[name];
    const newSetting = { ...showLine, [name]: !oldValue };
    setShowLine(newSetting);
  };
  let _min = moment(startOfDay).subtract(30, "minutes").valueOf();
  let _max = moment(startOfNextDay).subtract(30, "minutes").valueOf();

  //   console.log("RrProcessedData", RrProcessedData);

  if (loading) {
    return <SimpleNoDataMessageTool loadind={true} message={"Calculating..."} showTopImg={false} />;
  }

  if (hasNoData) {
    return <SimpleNoDataMessageTool loadind={true} message={"No Data"} showTopImg={false} />;
  }

  return (
    <Wrapper ref={ref}>
      {RrProcessedData.length === 0 ? (
        <SimpleNoDataMessageTool
          loadind={true}
          message={"There seems to be no BRPM data this day..."}
          showTopImg={false}
        />
      ) : (
        <div>
          <ComposedChart
            width={width}
            height={height}
            data={RrProcessedData}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <CartesianGrid stroke={GRID_STROKE_COLOR} strokeDasharray={GRID_DASH_ARRAY} strokeWidth={GRID_THICKNESS} />
            <XAxis
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              strokeWidth={AXES_STROKE_WIDTH}
              // interval={11}
              // dataKey={"slot"}
              tickSize={10}
              //   verticalCoordinatesGenerator={(props) => {
              dataKey={"t"}
              ticks={xticks}
              type="number"
              allowDataOverflow={true}
              domain={[startOfDay, startOfNextDay]}
              interval={0}
              tickCount={xticks.length}
              tickFormatter={xaxisTimeFormatter}
            />
            <YAxis
              dataKey={"respiration_rate"}
              // ticks={yticks}
              //   domain={([min, max]) => [0, max >= 20 ? max : 20]}
              domain={([min, max]) => [0, max > 20 ? Math.round(max + 1) + 1 : 20]}
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              type="number"
              strokeWidth={AXES_STROKE_WIDTH}
            />
            <Tooltip content={<Spo2Tooltip />} />
            <Line
              type="monotone"
              dataKey="ma"
              name="Moving Average"
              stroke={LINE_COLORS.baseline}
              strokeWidth={2}
              activeDot={true}
              connectNulls={true}
              //   hide={showLine["ma"] ? false : true}
              hide={true}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="baseline"
              //   name="Moving Average"
              stroke={LINE_COLORS.baseline}
              strokeWidth={2}
              activeDot={true}
              connectNulls={true}
              hide={showLine["weighted"] ? false : true}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="adpt"
              name="Adaptive"
              stroke={LINE_COLORS.adaptive}
              connectNulls={true}
              // strokeDasharray="5 5"
              //   hide={showLine["adpt"] ? false : true}
              hide={true}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="respiration_rate"
              name="Raw"
              stroke={LINE_COLORS.raw}
              strokeWidth={0.9}
              connectNulls={false}
              // strokeDasharray="5 5"
              hide={showLine["raw"] ? false : true}
              dot={false}
              //   dot={DOT_STROKE}
              activeDot={true}
              isAnimationActive={false}
            />
            {extraLines.map((l, i) => {
              return (
                <Line
                  key={i}
                  type={"monotone"}
                  dataKey={`respiration_rate_gap_${i}`}
                  isAnimationActive={false}
                  strokeWidth={2}
                  strokeDasharray="3 3"
                  stroke="#a0a0af90"
                  dot={false}
                  name={"D"}
                  legendType="none"
                  connectNulls={true}
                  //   hide={showLine["raw"] ? false : true}
                  hide={true}
                />
              );
            })}
            {/* <Legend /> */}
          </ComposedChart>
          <LineFilterContainer>
            {LINES.map((f, i) => {
              return (
                <LineFilterItem key={i}>
                  <LineFilterCheckbox
                    type="checkbox"
                    name={f.value}
                    checked={showLine[f.value]}
                    onChange={handleLineCheckbox}
                  />
                  <LineLabel color={f.color} bstyle={f.bstyle ? f.bstyle : "solid"} theme={theme}>
                    {f.label}
                  </LineLabel>
                </LineFilterItem>
              );
            })}
          </LineFilterContainer>
          {/* <label>
            Delta
            <input
              value={delta}
              onChange={(e) => {
                setDelta(e.target.value);
              }}
            />
          </label>
          <label>
            k
            <input
              value={kValue}
              onChange={(e) => {
                setKValue(e.target.value);
              }}
            />
          </label> */}
        </div>
      )}
    </Wrapper>
  );
};

export default PatientDayBRPMBaselineTool;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 300px;
  position: relative;
`;

const Spo2Tooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    let rr = payload[0].payload.respiration_rate;
    let baseline = payload[0].payload.baseline;
    if (rr) {
      rr = rr.toFixed(0);
    }
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format("HH:mm")}`}</TooltipRow>
        <TooltipRow className="label">{`Raw BRPM: ${rr}`}</TooltipRow>
        <TooltipRow className="label">{`Baseline: ${baseline}`}</TooltipRow>
        <TooltipRow className="label">{`Q: ${payload[0].payload.resp_q}`}</TooltipRow>
        {/* <strong className="label">{`Adaptive: ${payload[0].payload.adpt}`}</strong>
        <br /> */}
      </TooltipWrapper>
    );
  }

  return null;
};

const LineFilterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  // margin-left: 20px;
  justify-content: center;
`;

const LineFilterItem = styled.div`
  display: flex;
  align-items: baseline;
`;

const LineFilterCheckbox = styled.input`
  &[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #1e7efa;
  }
`;

const LineLabel = styled.label`
  font-weight: 500;
  color: ${(props) => (props.theme === "dark" ? "white" : "black")};
  &:before {
    display: inline-block;
    content: "";
    border: 1.5px solid ${(props) => props.color};
    border-style: ${(props) => props.bstyle};
    // border-top: 1rem solid ${(props) => props.color};
    width: 4rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;
