import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import DrawHelper from "../../../helpers/DrawHelper";
import useDimensions from "react-use-dimensions";
import {
  AXES_COLOR,
  AXES_STROKE_WIDTH,
  GRID_STROKE_COLOR,
  GRID_THICKNESS,
  GRID_DASH_ARRAY,
  LINE_COLORS,
  xaxisTimeFormatter,
} from "../../ui/templates";
import DoctorAPI from "../../../api/DoctorAPI";
import { CartesianGrid, ComposedChart, XAxis, YAxis, Tooltip, Line } from "recharts";
import SimpleNoDataMessageTool from "../../sleep/tools/SimpleNoDataMessageTool";
import BaselineHelper from "../../../helpers/BaselineHelper";

// const Y_DOMAIN = [80, 100];
// const SPOTS_PER_DAY = 12 * 24;
const LINES = [
  { value: "raw", label: "Raw", color: LINE_COLORS.raw, width: 1, show: true },
  {
    value: "weighted",
    label: "Baseline",
    color: LINE_COLORS.baseline,
    width: 2,
    show: true,
  },
];

const PatientDaySpo2BaselineTool = (props) => {
  let { uuid, date, hasNoData, theme, tz=0,baselineWindow = 15, points = [], loading = false } = props;
  const [ref, { width, height }] = useDimensions();
  // const [loading, setLoading] = useState(false);
  const [spo2RawData, setSpo2RawData] = useState([]);
  const [spo2ProcessedData, setSpo2ProcessedData] = useState([]);

  const [delta, setDelta] = useState(0.03);
  const [kValue, setKValue] = useState(0.2);
  const [ptime, setPtime] = useState(0);
  const [showLine, setShowLine] = useState({
    raw: true,
    ma: true,
    adpt: true,
    gap: true,
    weighted: true,
  });
  const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    if (!points || points.length === 0) {
      return;
    }
    let _points = points.filter((x) => x?.spo2_q);
    setSpo2RawData(_points);
    setSpo2ProcessedData(_points);
    setDataLength(_points.length);
  }, [date, uuid, points]);

  let extraLines = DrawHelper.getExtraDottedChartsData(spo2ProcessedData, "spo2");
  let spo2WithGaps = spo2ProcessedData;
  for (let sp of spo2WithGaps) {
    for (let el in extraLines) {
      const slot1 = extraLines[el]["points"][0].slot;
      const slot2 = extraLines[el]["points"][1].slot;
      if (slot1 === sp.slot) {
        sp[`spo2_gap_${el}`] = extraLines[el]["points"][0][`spo2_gap_${el}`];
      }
      if (slot2 === sp.slot) {
        sp[`spo2_gap_${el}`] = extraLines[el]["points"][1][`spo2_gap_${el}`];
      }

      // spo2WithGaps;
    }
  }

  // spo2ProcessedData.map((x, i) => {
  //   if (!x.dashed) {
  //     return 0;
  //   }
  //   if (x.dashed && !spo2ProcessedData[i + 1].dashed) {
  //     extraLines.push()
  //   }
  // });
  let xticks = BaselineHelper.getXaxisTicksEachHour(moment(date).startOf("day"));
  let yticks = [];
  let spo2List = spo2ProcessedData.map((x) => x.spo2).filter((x) => x !== null);

  let minSpo2 = Math.min(...spo2List);
  let ymin = Math.floor(minSpo2 / 10) * 10;
  if (ymin >= 90) {
    yticks = [80, 85, 90, 95, 100];
  } else if (ymin < 90 && ymin >= 80) {
    yticks = [80, 85, 90, 95, 100];
  } else if (ymin < 80 && ymin >= 70) {
    yticks = [70, 80, 90, 100];
  } else {
    const gap = Math.round((100 - ymin) / 5);
    for (let i = ymin; i <= 100; i += gap) {
      yticks.push(i);
    }
    yticks.push(100);
  }

  let startOfDay = moment(date).startOf("day").valueOf();
  let startOfNextDay = moment(date).startOf("day").add(1, "day").valueOf();
  const handleLineCheckbox = (evt) => {
    const name = evt.target.name;
    let oldValue = showLine[name];
    const newSetting = { ...showLine, [name]: !oldValue };
    setShowLine(newSetting);
  };
  let _min = moment(startOfDay).subtract(30, "minutes").valueOf();
  let _max = moment(startOfNextDay).subtract(30, "minutes").valueOf();
  // console.log("yticks", yticks);
  if (loading) {
    return <SimpleNoDataMessageTool loadind={true} message={"Calculating..."} showTopImg={false} />;
  }

  if (hasNoData) {
    return <SimpleNoDataMessageTool loadind={true} message={"No Data"} showTopImg={false} />;
  }

  return (
    <Wrapper ref={ref}>
      {spo2ProcessedData.length === 0 ? (
        <SimpleNoDataMessageTool
          loadind={true}
          message={"Fetching data..."}
          // showTopImg={false}
        />
      ) : (
        <div>
          {/* <div>LOADING:{loading ? "LOADING" : "loaded"}</div> */}
          <ComposedChart
            width={width}
            height={height}
            data={spo2ProcessedData}
            margin={{ top: 5, right: 0, left: -20, bottom: 5 }}
          >
            <CartesianGrid
              stroke={GRID_STROKE_COLOR}
              strokeWidth={GRID_THICKNESS}
              strokeDasharray={GRID_DASH_ARRAY}
              // verticalCoordinatesGenerator={(props) => {
              //   const gridNum = 24;
              //   let _width = props.offset.width;
              //   let offset = props.offset.left;
              //   let originalDomainStart = props.xAxis.originalDomain[0];
              //   let originalDomainEnd = props.xAxis.originalDomain[1];
              //   let gridPos = [];

              //   for (let i = 0; i < gridNum; i++) {
              //     // gridPos.push(40 + (i + 1) * (_width / gridNum));
              //     // console.log(
              //     //   (1800000 * i) / (originalDomainEnd - originalDomainStart)
              //     // );

              //     gridPos.push(
              //       offset +
              //         ((xticks[i] + 1800000 - originalDomainStart) /
              //           (originalDomainEnd - originalDomainStart)) *
              //           _width
              //     );
              //   }
              //   return gridPos;
              // }}
            />
            <XAxis
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              strokeWidth={AXES_STROKE_WIDTH}
              // interval={11}
              // dataKey={"slot"}
              dataKey={"t"}
              ticks={xticks}
              tickSize={10}
              type="number"
              allowDataOverflow={true}
              domain={[startOfDay, startOfNextDay]}
              interval={0}
              tickCount={xticks.length}
              tickFormatter={xaxisTimeFormatter}
            />
            <YAxis
              dataKey={"spo2"}
              ticks={yticks}
              domain={([min, max]) => [min, 100]}
              stroke={theme === "dark" ? "white" : AXES_COLOR}
              type="number"
              strokeWidth={AXES_STROKE_WIDTH}
            />
            <Tooltip content={<Spo2Tooltip />} />
            <Line
              type="monotone"
              dataKey="ma"
              // name="Moving Average"
              // stroke={LINE_COLORS.baseline}
              // stroke={"blue"}
              strokeWidth={2}
              activeDot={true}
              connectNulls={true}
              // hide={showLine["ma"] ? false : true}
              hide={true}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="adpt"
              // name="Adaptive"
              stroke={LINE_COLORS.adaptive}
              connectNulls={true}
              // strokeDasharray="5 5"
              // hide={showLine["adpt"] ? false : true}
              hide={true}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="baseline"
              // name="Weighted"
              stroke={LINE_COLORS.baseline}
              strokeWidth={2}
              connectNulls={true}
              // strokeDasharray="5 5"
              // hide={showLine["adpt"] ? false : true}
              hide={showLine["weighted"] ? false : true}
              dot={false}
              isAnimationActive={false}
            />
            <Line
              type="monotone"
              dataKey="spo2"
              name="Raw"
              stroke={LINE_COLORS.raw}
              strokeWidth={1}
              // connectNulls={true}
              // strokeDasharray="5 5"
              hide={showLine["raw"] ? false : true}
              // dot={dataLength < 100 ? true : false}
              // dot={DOT_STROKE}
              dot={false}
              isAnimationActive={false}
            />
            {extraLines.map((l, i) => {
              return (
                <Line
                  key={i}
                  type={"monotone"}
                  dataKey={`spo2_gap_${i}`}
                  isAnimationActive={false}
                  strokeWidth={2}
                  strokeDasharray="3 3"
                  stroke="#a0a0af90"
                  dot={false}
                  name={"D"}
                  legendType="none"
                  connectNulls={true}
                  // hide={showLine["raw"] ? false : true}
                  hide={true}
                />
              );
            })}
            {/* <Legend /> */}
          </ComposedChart>
          <LineFilterContainer>
            {LINES.map((f, i) => {
              return (
                <LineFilterItem key={i}>
                  <LineFilterCheckbox
                    type="checkbox"
                    name={f.value}
                    checked={showLine[f.value]}
                    onChange={handleLineCheckbox}
                  />
                  <LineLabel color={f.color} theme={theme} bstyle={f.bstyle ? f.bstyle : "solid"}>
                    {f.label}
                  </LineLabel>
                </LineFilterItem>
              );
            })}
          </LineFilterContainer>
          {/* <label>
            Delta
            <input
              value={delta}
              onChange={(e) => {
                setDelta(e.target.value);
              }}
            />
          </label>
          <label>
            k
            <input
              value={kValue}
              onChange={(e) => {
                setKValue(e.target.value);
              }}
            />
          </label> */}
        </div>
      )}
    </Wrapper>
  );
};

export default PatientDaySpo2BaselineTool;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  // height: 100%;
  height: 300px;
  position: relative;
`;

const Spo2Tooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <TooltipWrapper className="custom-tooltip">
        <TooltipRow className="label">{`${moment(label).format("HH:mm")}`}</TooltipRow>
        <TooltipRow className="label">{`Raw SPO2: ${payload[0].payload.spo2}`}</TooltipRow>
        {/* <TooltipRow className="label">{`Baseline: ${payload[0].payload.ma}`}</TooltipRow> */}
        <TooltipRow className="label">{`Baseline: ${payload[0].payload.baseline}`}</TooltipRow>
        <TooltipRow className="label">{`Q: ${payload[0].payload.spo2_q}`}</TooltipRow>
      </TooltipWrapper>
    );
  }

  return null;
};

const LineFilterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  // margin-left: 20px;
  justify-content: center;
`;

const LineFilterItem = styled.div`
  display: flex;
  align-items: baseline;
`;

const LineFilterCheckbox = styled.input`
  &[type="checkbox"] {
    transform: scale(1.2);
    accent-color: #1e7efa;
  }
`;

const LineLabel = styled.label`
  font-weight: 500;
  color: ${(props) => (props.theme === "dark" ? "white" : "black")};
  &:before {
    display: inline-block;
    content: "";
    border: 1.5px solid ${(props) => props.color};
    border-style: ${(props) => props.bstyle};
    // border-top: 1rem solid ${(props) => props.color};
    width: 4rem;
    margin: 0 10px;
    transform: translateY(-4px);
  }
`;

const TooltipWrapper = styled.div`
  border: 1px solid lightgrey;
  padding: 5px;
  background: white;
`;

const TooltipRow = styled.div`
  font-weight: normal;
  margin-bottom: 5px;
`;
