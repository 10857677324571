import React, { useState, useEffect, useRef, useCallback, useReducer, useMemo } from "react";
import moment from "moment";
import ls from "local-storage";
import styled from "styled-components";
import DrawHelper from "../../../helpers/DrawHelper";
import { RONAS_BLUE_MOUSE_OVER, RONAS_DARK_GREEN } from "../../../constants/config";
import { AXES_COLOR } from "../../ui/templates";

import useDimensions from "react-use-dimensions";

const getRandomItems = (num = 7, step = 86400000) => {
  let arr = Array.from({ length: num }).map((a, i) => 0);
  let from = moment()
    .add((-step * num) / 1000.0, "seconds")
    .startOf("day");
  for (let i in arr) {
    arr[i] = {
      t: from + step * i,
      value: Math.round(Math.random() * 10000),
    };
  }
  return arr;
};

const getMinMax = (items) => {
  if (items == undefined || items.length == 0 || items[0] == undefined) {
    return {};
  }
  try {
    let r0 = items[0];
    let min = items[0].value;
    let max = items[0].value;
    for (let i in items) {
      let v = items[i].value;
      if (+v > +max) {
        max = +v;
      }
      if (+v < +max) {
        min = +v;
      }
    }
    return { min, max };
  } catch (exc) {}
};

export default function SabirBarChart(props) {
  const {
    shouldChangeColorOfSelected = true,

    isHoursChart = false,
    valueSuffix = "",
    loading = false,
    items = getRandomItems(24),
    barColor = RONAS_DARK_GREEN,
    hoverBarColor = RONAS_BLUE_MOUSE_OVER,
    shouldHighlightToday = true,
    onItemClick = (item) => {},
    boxPaddingFraction = 0.175,
    maxLabelWidth = 30,
    renderItem = (it) => {
      return <span>{moment(it.t).format("HH:mm")}</span>;
    },
    selectedTimestamp = undefined,
    valueImg = undefined,
  } = props;
  const theme = ls.get("theme");
  const [isZero, setIsZero] = useState(true); // for animation
  useEffect(() => {
    let mounted = true;
    if (mounted == false) {
      return;
    }
    setIsZero(true);
    setTimeout(() => {
      if (mounted == false) {
        return;
      }
      setIsZero(false);
    }, 100);
    return () => {
      mounted = false;
    };
  }, [`${items.length}`]);

  const [contentRef, size] = useDimensions();
  // let size = useComponentSize(contentRef);
  let { min, max } = getMinMax(items);

  if (min == undefined || max == undefined) {
    return null;
  }

  let boxWidth = size.width / items.length;
  let boxSizePadding = boxWidth * boxPaddingFraction;
  let maxNum = (1.0 * size.width) / maxLabelWidth;
  let skip = Math.ceil((1.0 * items.length) / maxNum);
  let vertItems = DrawHelper.getStepsGridValues(max, 4);
  let maxY = vertItems[vertItems.length - 1];

  return (
    <Wrapper className="bar-chart-wrapper">
      <ContPlaceholder>
        <VertAxisPlaceholder>
          {vertItems.map((a, i) => {
            let dperc = size.height == 0 ? 0 : (12.0 * 100.0) / size.height;
            let perc = (100.0 * a) / maxY;
            if (i == vertItems.length - 1) {
              perc = perc - dperc;
            }
            // if (isNaN(perc)) {
              // console.log("SabirBarChart: bottoms", perc, size.height, dperc, maxY);
              // TODO: debug nan values here
            // }

            return (
              <VertItem key={a} bottom={perc} className="chart-vert-item">
                <VertItemInner>{loading == true ? "..." : `${a.toFixed(1)}`.replace(".0", "")}</VertItemInner>
              </VertItem>
            );
          })}
        </VertAxisPlaceholder>
        <Content ref={contentRef}>
          {items.map((it, i) => {
            let isSelected = selectedTimestamp == it.t;
            let hasLabel = +i % skip == 0;
            let isToday = +moment().startOf("day") == +moment(it.t).startOf("day");
            return (
              <Box key={`it_${it.value}_${i}`} pad={boxSizePadding} className="chart-item">
                <BoxInner className="chart-item">
                  <Bar
                    className="chart-item"
                    height={isZero ? 0 : (100.0 * it.value) / maxY}
                    color={barColor}
                    hovercolor={hoverBarColor}
                    today={isToday}
                    isselected={shouldChangeColorOfSelected && isSelected}
                    onClick={() => {
                      onItemClick(it);
                    }}
                  >
                    <BarInner className="chart-item">
                      <BarTooltip className="chart-item">
                        {valueImg == undefined ? null : (
                          <>
                            <StepsTooltipImg src={valueImg} className="chart-item" />
                            <br />
                          </>
                        )}
                        {`${it.value}`}
                        {valueSuffix == undefined || valueSuffix.trim() == "" ? null : (
                          <span style={{ marginLeft: 4 }} className="chart-item">
                            {valueSuffix}
                          </span>
                        )}
                      </BarTooltip>
                    </BarInner>
                  </Bar>
                </BoxInner>
                {hasLabel == false ? null : (
                  <BarLabel className="chart-item" today={isToday && shouldHighlightToday}>
                    {loading == true ? "..." : renderItem(it)}
                  </BarLabel>
                )}
              </Box>
            );
          })}
        </Content>
      </ContPlaceholder>
      <HorAxisPlaceholder></HorAxisPlaceholder>
    </Wrapper>
  );
}

const StepsTooltipImg = styled.img`
  height: 16px;
  width: 16px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: white;
`;

const horHeight = 20;
const vertWidth = 20;

const VertItem = styled.div`
  position: absolute;
  bottom: ${(props) => props.bottom}%;
  right: 0px;
  // right: ${4}px;
  // color: grey;
`;

const VertItemInner = styled.div`
  height: 12px;
  line-height: 12px;
  margin-top: -6px;
  padding-right: 4px;
  box-sizing: border-box;
  font-size: 10px;
`;

const HorAxisPlaceholder = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: ${horHeight}px;
  border-top: 1px solid whitesmoke;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  border-bottom: 1px solid ${AXES_COLOR};
  position: relative;
`;

const Box = styled.div`
  box-sizing: border-box;
  flex: 1;
  border-right: 1px dashed whitesmoke;
  padding-left: ${(props) => props.pad}px;
  padding-right: ${(props) => props.pad}px;
  position: relative;
`;

const BoxInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Bar = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: ${(props) => props.height}%;
  background: ${(props) => (props.isselected == true ? "blue" : props.color)};
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  cursor: pointer;
  transition: height 0.35s linear;

  :hover {
    background: ${(props) => (props.hovercolor != undefined ? props.hovercolor : props.color)};
  }
`;

const BarInner = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

const BarTooltip = styled.div`
  display: none;
  position: absolute;
  top: -32px;
  line-height: 12px;
  font-size: 12px;
  text-align: center;
  left: 0px;
  right: 0px;
  color: ${RONAS_BLUE_MOUSE_OVER};
  border-radius: 6px;
  font-weight: bold;

  ${BarInner}:hover & {
    display: block;
  }
`;

const ContPlaceholder = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  box-sizing: border-box;
`;

const BarLabel = styled.div`
  bottom: -${horHeight}px;
  height: ${horHeight}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0px;
  right: 0px;
  text-align: right;
  font-size: 11px;
  font-weight: ${(props) => (props.today ? "bold" : "normal")};
  color: ${(props) => (props.today ? RONAS_DARK_GREEN : "grey")};
`;

const VertAxisPlaceholder = styled.div`
  width: ${vertWidth}px;
  box-sizing: border-box;
  opacity: 50%;
  border-right: 1px solid ${AXES_COLOR};
  position: relative;
`;
